import type {
  Option,
  RegionOption,
  RegionsOptionsMap,
  OptionsMap,
  Entries,
} from '@/types';

import _keyBy from 'lodash/keyBy';

export const THEME_LIGHT = 'light';

export const THEME_DARK = 'dark';

export const THEME_COLOR_AVOCADO = 'avocado';

export const THEME_COLOR_BLUE = 'blue';

export const THEME_COLOR_GREEN = 'green';

export const THEME_COLOR_ORANGE = 'orange';

export const THEME_COLOR_PURPLE = 'purple';

export const THEME_COLOR_RED = 'red';

export const THEME_COLOR_TURQUOISE = 'turquoise';

export const THEME_COLOR_YELLOW = 'yellow';

export const THEMES_COLORS = [
  {
    value: THEME_COLOR_AVOCADO,
    label: 'Avocado',
  },
  {
    value: THEME_COLOR_BLUE,
    label: 'Blue',
  },
  {
    value: THEME_COLOR_GREEN,
    label: 'Green',
  },
  {
    value: THEME_COLOR_ORANGE,
    label: 'Orange',
  },
  {
    value: THEME_COLOR_PURPLE,
    label: 'Purple',
  },
  {
    value: THEME_COLOR_RED,
    label: 'Red',
  },
  {
    value: THEME_COLOR_TURQUOISE,
    label: 'Turquoise',
  },
  {
    value: THEME_COLOR_YELLOW,
    label: 'Yellow',
  },
] as Option[];

export const TRAFFIC_TYPE_POP = 1;

export const TRAFFIC_TYPE_PUSH = 2;

export const TRAFFIC_TYPE_IN_PAGE_PUSH = 3;

export const TRAFFIC_TYPE_NATIVE = 4;

export const TRAFFIC_TYPE_BANNER = 5;

export const TRAFFIC_TYPE_VIDEO = 6;

// TODO: Избавиться от использования translate_key
export const TRAFFIC_TYPES = [
  {
    value: TRAFFIC_TYPE_POP,
    label: 'Pop',
    translate_key: 'traffic_type_pop',
  },
  {
    value: TRAFFIC_TYPE_PUSH,
    label: 'Push',
    translate_key: 'traffic_type_push',
  },
  {
    value: TRAFFIC_TYPE_IN_PAGE_PUSH,
    label: 'In-page Push',
    translate_key: 'traffic_type_in_page_push',
  },
  {
    value: TRAFFIC_TYPE_NATIVE,
    label: 'Native',
    translate_key: 'traffic_type_native',
  },
  {
    value: TRAFFIC_TYPE_BANNER,
    label: 'Banner',
    translate_key: 'traffic_type_banner',
  },
  {
    value: TRAFFIC_TYPE_VIDEO,
    label: 'Video',
    translate_key: 'traffic_type_video',
  },
] as Option[];

export const TRAFFIC_TYPES_MAP = _keyBy(TRAFFIC_TYPES, 'value') as OptionsMap;

export const TRAFFIC_TYPE_MIX = new Map();

TRAFFIC_TYPE_MIX.set(TRAFFIC_TYPE_POP, [TRAFFIC_TYPE_POP]);

TRAFFIC_TYPE_MIX.set(TRAFFIC_TYPE_PUSH, [TRAFFIC_TYPE_PUSH, TRAFFIC_TYPE_IN_PAGE_PUSH]);

TRAFFIC_TYPE_MIX.set(TRAFFIC_TYPE_IN_PAGE_PUSH, [TRAFFIC_TYPE_IN_PAGE_PUSH, TRAFFIC_TYPE_PUSH]);

TRAFFIC_TYPE_MIX.set(TRAFFIC_TYPE_NATIVE, [TRAFFIC_TYPE_NATIVE]);

TRAFFIC_TYPE_MIX.set(TRAFFIC_TYPE_BANNER, [TRAFFIC_TYPE_BANNER]);

TRAFFIC_TYPE_MIX.set(TRAFFIC_TYPE_VIDEO, [TRAFFIC_TYPE_VIDEO]);

export const TRAFFIC_CATEGORY_MAINSTREAM = 1;

export const TRAFFIC_CATEGORY_ADULT = 2;

// TODO: Избавиться от использования translate_key
export const TRAFFIC_CATEGORIES = [
  {
    value: TRAFFIC_CATEGORY_MAINSTREAM,
    label: 'Mainstream',
    translate_key: 'traffic_category_mainstream',
  },
  {
    value: TRAFFIC_CATEGORY_ADULT,
    label: 'Non-mainstream 🍓',
    translate_key: 'traffic_category_adult',
  },
] as Option[];

export const TRAFFIC_CATEGORIES_MAP = _keyBy(TRAFFIC_CATEGORIES, 'value') as OptionsMap;

export const VERTICAL_GENERAL = 1;

export const VERTICAL_CREDIT_CARDS = 2;

export const VERTICAL_DATING = 3;

export const VERTICALS = [
  {
    value: VERTICAL_GENERAL,
    label: 'General',
  },
  {
    value: VERTICAL_CREDIT_CARDS,
    label: 'Credit cards',
  },
  {
    value: VERTICAL_DATING,
    label: 'Dating',
  },
] as Option[];

export const VERTICALS_MAP = _keyBy(VERTICALS, 'value') as OptionsMap;

export const TRAFFIC_SOURCE_RTB = 1;

export const TRAFFIC_SOURCE_DIRECT = 2;

export const TRAFFIC_SOURCES = [
  {
    value: null,
    translate_key: 'any_male',
  },
  {
    value: TRAFFIC_SOURCE_RTB,
    translate_key: 'traffic_source_rtb',
  },
  {
    value: TRAFFIC_SOURCE_DIRECT,
    translate_key: 'traffic_source_direct',
  },
] as Option[];

export const BIDDER_FEED = 1;

export const BIDDER_ADVERTISER = 2;

export const BIDDERS = [
  {
    value: null,
    translate_key: 'any_male',
  },
  {
    value: BIDDER_FEED,
    translate_key: 'feed',
  },
  {
    value: BIDDER_ADVERTISER,
    translate_key: 'advertiser',
  },
] as Option[];

export const REGION_EU = 1;

export const REGION_US = 2;

export const REGION_AS = 3;

export const REGIONS = [
  {
    value: REGION_EU,
    translate_key: 'region_eu',
    subdomain: 'eu.',
  },
  {
    value: REGION_US,
    translate_key: 'region_us',
    subdomain: 'us.',
  },
  {
    value: REGION_AS,
    translate_key: 'region_as',
    subdomain: 'as.',
  },
] as RegionOption[];

export const REGIONS_MAP = _keyBy(REGIONS, 'value') as RegionsOptionsMap;

export const BID_SUBJECT_CLICK = 1;

export const BID_SUBJECT_IMPRESSION = 2;

export const BID_SUBJECTS = [
  {
    value: BID_SUBJECT_CLICK,
    label: 'bid_subject_click',
  },
  {
    value: BID_SUBJECT_IMPRESSION,
    label: 'bid_subject_impression',
  },
] as Option[];

export const BID_TYPE_PER_ONE = 1;

export const BID_TYPE_PER_THOUSAND = 2;

export const BID_TYPES = [
  {
    value: BID_TYPE_PER_ONE,
    label: 'Per one',
  },
  {
    value: BID_TYPE_PER_THOUSAND,
    label: 'Per thousand',
  },
] as Option[];

export const ENDPOINT_MAX_QPS_0 = 0;

export const ENDPOINT_MAX_QPS_500 = 500;

export const ENDPOINT_MAX_QPS_1000 = 1000;

export const ENDPOINT_MAX_QPS_2000 = 2000;

export const ENDPOINT_MAX_QPS_3000 = 3000;

export const ENDPOINT_MAX_QPS_4000 = 4000;

export const ENDPOINT_MAX_QPS_5000 = 5000;

export const ENDPOINT_MAX_QPS_10000 = 10000;

export const ENDPOINT_MAX_QPS = [
  {
    value: ENDPOINT_MAX_QPS_0,
    label: 'Unlimited',
  },
  {
    value: ENDPOINT_MAX_QPS_500,
    label: '500 QPS',
  },
  {
    value: ENDPOINT_MAX_QPS_1000,
    label: '1000 QPS',
  },
  {
    value: ENDPOINT_MAX_QPS_2000,
    label: '2000 QPS',
  },
  {
    value: ENDPOINT_MAX_QPS_3000,
    label: '3000 QPS',
  },
  {
    value: ENDPOINT_MAX_QPS_4000,
    label: '4000 QPS',
  },
  {
    value: ENDPOINT_MAX_QPS_5000,
    label: '5000 QPS',
  },
  {
    value: ENDPOINT_MAX_QPS_10000,
    label: '10000 QPS',
  },
] as Option[];

export const PAYOUT_PERIOD_WEEKLY = 1;

export const PAYOUT_PERIOD_BIWEEKLY = 2;

export const PAYOUT_PERIOD_NET_15 = 3;

export const PAYOUT_PERIOD_DAILY = 4;

export const PAYOUT_PERIODS = [
  {
    value: PAYOUT_PERIOD_WEEKLY,
    label: 'Weekly',
  },
  {
    value: PAYOUT_PERIOD_BIWEEKLY,
    label: 'Bi-Weekly',
  },
  {
    value: PAYOUT_PERIOD_NET_15,
    label: 'Net 15',
  },
  {
    value: PAYOUT_PERIOD_DAILY,
    label: 'Daily',
  },
] as Option[];

export const BID_MULTIPLIER = 1000000000;

export const TARGETING_TYPE_DISABLED = 0;

export const TARGETING_TYPE_WHITE_LIST = 1;

export const TARGETING_TYPE_BLACK_LIST = 2;

export const TARGETING_TYPES = [
  {
    value: TARGETING_TYPE_DISABLED,
    translate_key: 'all',
  },
  {
    value: TARGETING_TYPE_WHITE_LIST,
    translate_key: 'include',
  },
  {
    value: TARGETING_TYPE_BLACK_LIST,
    translate_key: 'exclude',
  },
] as Option[];

export const TARGETING_TYPES_BY_TRAFFIC_CATEGORY = [
  {
    value: TARGETING_TYPE_DISABLED,
    translate_key: 'disabled_male',
  },
  {
    value: TRAFFIC_CATEGORY_MAINSTREAM,
    translate_key: 'traffic_category_mainstream',
  },
  {
    value: TRAFFIC_CATEGORY_ADULT,
    translate_key: 'traffic_category_adult',
  },
];

export const TARGETING_TYPES_BY_TRAFFIC_SOURCE = [
  {
    value: TARGETING_TYPE_DISABLED,
    translate_key: 'disabled_male',
  },
  {
    value: TRAFFIC_SOURCE_RTB,
    translate_key: 'traffic_source_rtb',
  },
  {
    value: TRAFFIC_SOURCE_DIRECT,
    translate_key: 'traffic_source_direct',
  },
];

export const TARGETING_TYPES_BY_PSEUDOSITES = [
  {
    value: TARGETING_TYPE_DISABLED,
    translate_key: 'disabled_male',
  },
  {
    value: TARGETING_TYPE_WHITE_LIST,
    translate_key: 'include',
  },
  {
    value: TARGETING_TYPE_BLACK_LIST,
    translate_key: 'exclude',
  },
] as Option[];

export const SUBSCRIBER_AGE_3 = 3;

export const SUBSCRIBER_AGE_10 = 10;

export const SUBSCRIBER_AGE_30 = 30;

export const TARGETING_TYPES_BY_SUBSCRIBER_AGE: Option[] = [
  {
    value: TARGETING_TYPE_DISABLED,
    translate_key: 'all',
  },
  {
    value: SUBSCRIBER_AGE_3,
    translate_key: 'subscriber_age_3',
  },
  {
    value: SUBSCRIBER_AGE_10,
    translate_key: 'subscriber_age_10',
  },
  {
    value: SUBSCRIBER_AGE_30,
    translate_key: 'subscriber_age_30',
  },
];

export const PROTOCOL_LEGACY = 1;

export const PROTOCOL_OPENRTB = 2;

export const PROTOCOL_DIRECT_LINK = 3;

export const PROTOCOLS = [
  {
    value: PROTOCOL_LEGACY,
    translate_key: 'protocol_legacy',
  },
  {
    value: PROTOCOL_OPENRTB,
    translate_key: 'protocol_openrtb',
  },
  {
    value: PROTOCOL_DIRECT_LINK,
    translate_key: 'protocol_direct_link',
  },
] as Option[];

export const PROTOCOLS_MAP: OptionsMap = _keyBy(PROTOCOLS, 'value');

export const ENTRIES: Entries = {
  data: [],
  meta: {
    total: 0,
  },
};

export const OPENRTB_DEVICE_TYPE_MOBILE_TABLET = 1;

export const OPENRTB_DEVICE_TYPE_PERSONAL_COMPUTER = 2;

export const ENTRIES_PER_PAGE = 100;

export const ENTRIES_SORT_BY_ID = 'id';

export const ENTRIES_SORT_BY_DIRECTION_ASC = 'asc';

export const ENTRIES_SORT_BY_DIRECTION_DESC = 'desc';

// Константы проектов для "костылей"

export const PROJECT_ID_ADBISON = 4;

export const PROJECT_ID_TRAFFBAY = 5;

export const PROJECT_ID_DEMO = 6;

export const PROJECT_ID_ADKELA = 9;

export const PRESELECTED_PROJECTS_IDS = [
  2, // Advinci
  3, // MediaModern
  PROJECT_ID_ADBISON,
  PROJECT_ID_TRAFFBAY,
  7, // AdsMelody
  8, // AdsBound
  10, // MobAdWise
];

//

export const PROJECT_STATUS_ENABLED = 1;

export const PROJECT_STATUS_DISABLED = 2;

export const PROJECT_STATUSES = [
  {
    value: PROJECT_STATUS_ENABLED,
    translate_key: 'enabled_male',
    severity: 'success',
  },
  {
    value: PROJECT_STATUS_DISABLED,
    translate_key: 'disabled_male',
    severity: 'warning',
  },
] as Option[];

export const PROJECT_STATUSES_MAP = _keyBy(PROJECT_STATUSES, 'value') as OptionsMap;

export const USER_TYPE_SUPER_ADMIN = 1;

export const USER_TYPE_ADMIN = 2;

export const USER_TYPE_MANAGER = 3;

export const USER_TYPE_AFFILIATE = 4;

export const USER_TYPES = [
  {
    value: USER_TYPE_SUPER_ADMIN,
    translate_key: 'super_admin',
    severity: 'success',
    emoji: '😎',
  },
  {
    value: USER_TYPE_ADMIN,
    translate_key: 'admin',
    severity: 'success',
    emoji: '😎',
  },
  {
    value: USER_TYPE_MANAGER,
    translate_key: 'manager',
    severity: 'info',
    emoji: '🤠',
  },
  {
    value: USER_TYPE_AFFILIATE,
    translate_key: 'user',
  },
] as Option[];

export const USER_TYPES_MAP = _keyBy(USER_TYPES, 'value') as OptionsMap;

export const USER_STATUS_UNVERIFIED = 1;

export const USER_STATUS_ACTIVE = 2;

export const USER_STATUS_BANNED = 3;

export const USER_STATUSES = [
  {
    value: USER_STATUS_UNVERIFIED,
    translate_key: 'unverified_male',
    severity: 'info',
    cssClass: 'text-blue-300',
  },
  {
    value: USER_STATUS_ACTIVE,
    translate_key: 'active_male',
    severity: 'success',
    cssClass: 'text-green-300',
  },
  {
    value: USER_STATUS_BANNED,
    translate_key: 'banned_male',
    severity: 'danger',
    cssClass: 'text-pink-300',
  },
] as Option[];

export const USER_STATUSES_MAP = _keyBy(USER_STATUSES, 'value') as OptionsMap;

export const USER_DEFAULT_MAX_SITES = 0;

export const USER_ROLE_PUBLISHER = 1;

export const USER_ROLE_ADVERTISER = 2;

export const USER_ROLES = [
  {
    value: USER_ROLE_PUBLISHER,
    translate_key: 'publisher',
  },
  {
    value: USER_ROLE_ADVERTISER,
    translate_key: 'advertiser',
  },
] as Option[];

export const ENDPOINT_STATUS_ENABLED = 1;

export const ENDPOINT_STATUS_DISABLED = 2;

export const ENDPOINT_STATUSES = [
  {
    value: ENDPOINT_STATUS_ENABLED,
    translate_key: 'enabled_male',
    severity: 'success',
    cssClass: 'text-green-300',
  },
  {
    value: ENDPOINT_STATUS_DISABLED,
    translate_key: 'disabled_male',
    severity: 'warning',
    cssClass: 'text-pink-300',
  },
] as Option[];

export const ENDPOINT_STATUSES_MAP = _keyBy(ENDPOINT_STATUSES, 'value') as OptionsMap;

export const ENDPOINT_DEFAULT_SHAVE = 0;

export const ENDPOINT_DEFAULT_POP_FREQUENCY_SECONDS = 60;

// Константы сайта

export const SITE_APPROVAL_STATUS_PENDING_APPROVAL = 1;

export const SITE_APPROVAL_STATUS_APPROVED = 2;

export const SITE_APPROVAL_STATUS_DECLINED = 3;

export const SITE_APPROVAL_STATUSES = [
  {
    value: SITE_APPROVAL_STATUS_PENDING_APPROVAL,
    translate_key: 'pending_approval',
    severity: 'warning',
    cssClass: 'text-yellow-400',
  },
  {
    value: SITE_APPROVAL_STATUS_APPROVED,
    translate_key: 'approved_male',
    severity: 'success',
    cssClass: 'text-green-400',
  },
  {
    value: SITE_APPROVAL_STATUS_DECLINED,
    translate_key: 'declined_male',
    severity: 'danger',
    cssClass: 'text-pink-400',
  },
] as Option[];

export const SITE_APPROVAL_STATUSES_MAP = _keyBy(SITE_APPROVAL_STATUSES, 'value') as OptionsMap;

// Креатив для Push и In-Page Push кампаний
export const CREATIVE_TYPE_PUSH_FAMILY = 1;

// Креатив для Native кампаний
export const CREATIVE_TYPE_NATIVE = 2;

export const CREATIVE_TYPES: Option[] = [
  {
    value: CREATIVE_TYPE_PUSH_FAMILY,
    label: 'Push & In-Page Push',
  },
  {
    value: CREATIVE_TYPE_NATIVE,
    label: 'Native',
  },
];

export const CREATIVE_TYPES_MAP: OptionsMap = _keyBy(CREATIVE_TYPES, 'value');

export const CREATIVE_APPROVAL_STATUS_PENDING_APPROVAL = 1;

export const CREATIVE_APPROVAL_STATUS_APPROVED = 2;

export const CREATIVE_APPROVAL_STATUS_DECLINED = 3;

export const CREATIVE_APPROVAL_STATUSES = [
  {
    value: CREATIVE_APPROVAL_STATUS_PENDING_APPROVAL,
    translate_key: 'pending_approval',
    severity: 'warning',
    cssClass: 'text-yellow-300',
  },
  {
    value: CREATIVE_APPROVAL_STATUS_APPROVED,
    translate_key: 'approved_male',
    severity: 'success',
    cssClass: 'text-green-300',
  },
  {
    value: CREATIVE_APPROVAL_STATUS_DECLINED,
    translate_key: 'declined_male',
    severity: 'danger',
    cssClass: 'text-pink-300',
  },
] as Option[];

export const CREATIVE_APPROVAL_STATUSES_MAP = _keyBy(CREATIVE_APPROVAL_STATUSES, 'value') as OptionsMap;

export const CAMPAIGN_CREATIVE_TYPE_BUILTIN = 1; // "Встроенный" в кампанию креатив (для обратной совместимости)

export const CAMPAIGN_CREATIVE_TYPE_MULTIPLE = 2;

export const CAMPAIGN_CREATIVE_TYPES = [
  {
    value: CAMPAIGN_CREATIVE_TYPE_BUILTIN,
    translate_key: 'creative_type_builtin',
    disabled: true,
  },
  {
    value: CAMPAIGN_CREATIVE_TYPE_MULTIPLE,
    translate_key: 'creative_type_multiple',
  },
] as Option[];

// 1:1
export const CREATIVE_IMAGE_ASPECT_RATIO_W1H1 = 1;

// 4:3
export const CREATIVE_IMAGE_ASPECT_RATIO_W4H3 = 1.33;

// 1.97:1
export const CREATIVE_IMAGE_ASPECT_RATIO_W1D97H1 = 1.97;

export const CREATIVE_IMAGE_ASPECT_RATIO_LABEL_MAP = {
  [CREATIVE_IMAGE_ASPECT_RATIO_W1H1]: '1:1',
  [CREATIVE_IMAGE_ASPECT_RATIO_W4H3]: '4:3',
  [CREATIVE_IMAGE_ASPECT_RATIO_W1D97H1]: '1.97:1',
};

export const CAMPAIGN_PAYMENT_TYPE_CPC = 1;

export const CAMPAIGN_PAYMENT_TYPE_CPA = 2;

export const CAMPAIGN_PAYMENT_TYPES = [
  {
    value: CAMPAIGN_PAYMENT_TYPE_CPC,
    label: 'CPC',
  },
  {
    value: CAMPAIGN_PAYMENT_TYPE_CPA,
    label: 'CPA',
    disabled: true,
  },
] as Option[];

export const CAMPAIGN_PAYMENT_TYPES_MAP = _keyBy(CAMPAIGN_PAYMENT_TYPES, 'value') as OptionsMap;

export const CAMPAIGN_MAX_SPENDING_PER_DAY_STRATEGY_FAST = 1;

export const CAMPAIGN_MAX_SPENDING_PER_DAY_STRATEGY_SMOOTHLY = 2;

export const CAMPAIGN_MAX_SPENDING_PER_DAY_STRATEGIES: Option[] = [
  {
    value: CAMPAIGN_MAX_SPENDING_PER_DAY_STRATEGY_FAST,
    translate_key: 'fast',
  },
  {
    value: CAMPAIGN_MAX_SPENDING_PER_DAY_STRATEGY_SMOOTHLY,
    translate_key: 'smoothly',
  },
];

export const CAMPAIGN_MAX_SPENDING_PER_DAY_STRATEGIES_MAP = _keyBy(CAMPAIGN_MAX_SPENDING_PER_DAY_STRATEGIES, 'value') as OptionsMap;

export const CAMPAIGN_APPROVAL_STATUS_PENDING_APPROVAL = 1;

export const CAMPAIGN_APPROVAL_STATUS_APPROVED = 2;

export const CAMPAIGN_APPROVAL_STATUS_DECLINED = 3;

export const CAMPAIGN_APPROVAL_STATUSES = [
  {
    value: CAMPAIGN_APPROVAL_STATUS_PENDING_APPROVAL,
    translate_key: 'pending_approval',
    severity: 'warning',
    cssClass: 'text-yellow-300',
  },
  {
    value: CAMPAIGN_APPROVAL_STATUS_APPROVED,
    translate_key: 'approved_male',
    severity: 'success',
    cssClass: 'text-green-300',
  },
  {
    value: CAMPAIGN_APPROVAL_STATUS_DECLINED,
    translate_key: 'declined_male',
    severity: 'danger',
    cssClass: 'text-pink-300',
  },
] as Option[];

export const CAMPAIGN_APPROVAL_STATUSES_MAP = _keyBy(CAMPAIGN_APPROVAL_STATUSES, 'value') as OptionsMap;

export const CAMPAIGN_STATUS_ENABLED = 1;

export const CAMPAIGN_STATUS_DISABLED = 2;

export const CAMPAIGN_STATUSES = [
  {
    value: CAMPAIGN_STATUS_ENABLED,
    translate_key: 'enabled_male',
    severity: 'success',
    cssClass: 'text-green-300',
  },
  {
    value: CAMPAIGN_STATUS_DISABLED,
    translate_key: 'disabled_male',
    severity: 'danger',
    cssClass: 'text-danger-300',
  },
] as Option[];

export const CAMPAIGN_STATUSES_MAP = _keyBy(CAMPAIGN_STATUSES, 'value') as OptionsMap;

export const CAMPAIGN_CONNECTION_TYPE_ALL = 1;

export const CAMPAIGN_CONNECTION_TYPE_NON_CARRIER = 2;

export const CAMPAIGN_CONNECTION_TYPE_CARRIER = 3;

export const CAMPAIGN_CONNECTION_TYPES = [
  {
    value: CAMPAIGN_CONNECTION_TYPE_ALL,
    translate_key: 'campaign_connection_type_all',
  },
  {
    value: CAMPAIGN_CONNECTION_TYPE_NON_CARRIER,
    translate_key: 'campaign_connection_type_non_carrier',
  },
  {
    value: CAMPAIGN_CONNECTION_TYPE_CARRIER,
    translate_key: 'campaign_connection_type_carrier',
  },
] as Option[];

export const CAMPAIGN_DEFAULT_REVSHARE = 110;

export const CAMPAIGN_DEFAULT_MAX_CLICKS_PER_IP = 3;

export const CAMPAIGN_MIN_BID = 0.0002;

export const CAMPAIGN_TOO_HIGH_BID_POP = 0.01;

export const SUBID_STATUS_NEW = 1;

export const SUBID_STATUS_AUTO_APPROVED = 2;

export const SUBID_STATUS_AUTO_DECLINED = 3;

export const SUBID_STATUS_AUTO_CHECKING_DELAYED = 4;

export const SUBID_STATUS_DECLINED = 5;

export const SUBID_STATUSES = [
  {
    value: SUBID_STATUS_NEW,
    translate_key: 'new_male',
    cssClass: 'text-blue-400',
  },
  {
    value: SUBID_STATUS_AUTO_APPROVED,
    translate_key: 'approved_male',
    cssClass: 'text-green-400',
  },
  {
    value: SUBID_STATUS_AUTO_DECLINED,
    translate_key: 'declined_male',
    cssClass: 'text-orange-400',
  },
  {
    value: SUBID_STATUS_AUTO_CHECKING_DELAYED,
    translate_key: 'checking',
    cssClass: 'text-purple-400',
  },
  {
    value: SUBID_STATUS_DECLINED,
    translate_key: 'declined_male',
    cssClass: 'text-pink-400',
  },
] as Option[];

export const SUBID_STATUSES_MAP = _keyBy(SUBID_STATUSES, 'value') as OptionsMap;

export const SCHEME_CONTENT_TYPE_JSON = 1;

export const SCHEME_CONTENT_TYPE_XML = 2;

export const SCHEME_CONTENT_TYPES = [
  {
    value: SCHEME_CONTENT_TYPE_JSON,
    label: 'JSON',
  },
  {
    value: SCHEME_CONTENT_TYPE_XML,
    label: 'XML',
  },
] as Option[];

export const FEED_STATUS_ENABLED = 1;

export const FEED_STATUS_DISABLED = 3;

export const FEED_STATUSES = [
  {
    value: FEED_STATUS_ENABLED,
    translate_key: 'enabled_male',
    severity: 'success',
    cssClass: 'text-green-300',
  },
  {
    value: FEED_STATUS_DISABLED,
    translate_key: 'disabled_male',
    severity: 'warning',
    cssClass: 'text-pink-300',
  },
] as Option[];

export const FEED_STATUSES_MAP = _keyBy(FEED_STATUSES, 'value') as OptionsMap;

export const FEED_CUSTOMIZER_EXOCLICK = 1;

export const FEED_CUSTOMIZER_ADXAD = 2;

export const FEED_CUSTOMIZER_TRAFFICSTARS = 3;

export const FEED_CUSTOMIZER_KADAM = 4;

export const FEED_CUSTOMIZER_GEOZO = 5;

export const FEED_CUSTOMIZER_POPCASH = 6;

export const FEED_CUSTOMIZER_TRAFFIC_NOMADS = 7;

export const FEED_CUSTOMIZER_TRAFFIC_GALAKSION = 8;

export const FEED_CUSTOMIZER_TRAFFIC_NOVICLICK = 9;

export const FEED_CUSTOMIZER_TRAFFIC_OCTOCLICK = 10;

export const FEED_CUSTOMIZERS: Option[] = [
  {
    value: FEED_CUSTOMIZER_EXOCLICK,
    label: 'ExoClick',
  },
  {
    value: FEED_CUSTOMIZER_ADXAD,
    label: 'ADxAD',
  },
  {
    value: FEED_CUSTOMIZER_TRAFFICSTARS,
    label: 'TrafficStars',
  },
  {
    value: FEED_CUSTOMIZER_KADAM,
    label: 'Kadam',
  },
  {
    value: FEED_CUSTOMIZER_GEOZO,
    label: 'Geozo',
  },
  {
    value: FEED_CUSTOMIZER_POPCASH,
    label: 'PopCash',
  },
  {
    value: FEED_CUSTOMIZER_TRAFFIC_NOMADS,
    label: 'Traffic Nomads',
  },
  {
    value: FEED_CUSTOMIZER_TRAFFIC_GALAKSION,
    label: 'Galaksion',
  },
  {
    value: FEED_CUSTOMIZER_TRAFFIC_NOVICLICK,
    label: 'Noviclick',
  },
  {
    value: FEED_CUSTOMIZER_TRAFFIC_OCTOCLICK,
    label: 'Octoclick',
  },
];

export const FEED_CUSTOMIZERS_MAP: OptionsMap = _keyBy(FEED_CUSTOMIZERS, 'value');

export const QUALITY_PRESET_COMPARATORS = [
  'max_clicks_filtered_percent',
  'max_clicks_filtered_by_not_checked_percent',
  'max_clicks_filtered_by_proxy_percent',
  'max_clicks_filtered_by_has_no_js_percent',
  'max_clicks_filtered_by_iframe_percent',
  'max_clicks_filtered_by_headless_percent',
  'min_clicks_has_cookies_percent',
  'min_clicks_has_social_auth_percent',
  'min_clicks_landing_ctr_good',
];

export const CUSTOM_RESPONSE_PROVIDER_ROLLERADS = 1;

export const CUSTOM_RESPONSE_PROVIDER_EXOCLICK = 2;

export const CUSTOM_RESPONSE_PROVIDER_TRAFFICSTARS = 3;

export const CUSTOM_RESPONSE_PROVIDER_MGID = 4;

export const CUSTOM_RESPONSE_PROVIDER_NOVICLICK = 5;

export const CUSTOM_RESPONSE_PROVIDER_KADAM = 6;

export const CUSTOM_RESPONSE_PROVIDER_GALAKSION = 7;

export const CUSTOM_RESPONSE_PROVIDER_ADSTERRA = 8;

export const CUSTOM_RESPONSE_PROVIDER_EROADVERTISING = 9;

export const CUSTOM_RESPONSE_PROVIDERS: Option[] = [
  {
    value: CUSTOM_RESPONSE_PROVIDER_ROLLERADS,
    label: 'RollerAds',
  },
  {
    value: CUSTOM_RESPONSE_PROVIDER_EXOCLICK,
    label: 'ExoClick',
  },
  {
    value: CUSTOM_RESPONSE_PROVIDER_TRAFFICSTARS,
    label: 'TrafficStars',
  },
  {
    value: CUSTOM_RESPONSE_PROVIDER_MGID,
    label: 'MGID',
  },
  {
    value: CUSTOM_RESPONSE_PROVIDER_NOVICLICK,
    label: 'Noviclick',
  },
  {
    value: CUSTOM_RESPONSE_PROVIDER_KADAM,
    label: 'Kadam',
  },
  {
    value: CUSTOM_RESPONSE_PROVIDER_GALAKSION,
    label: 'Galaksion',
  },
  {
    value: CUSTOM_RESPONSE_PROVIDER_ADSTERRA,
    label: 'Adsterra',
  },
  {
    value: CUSTOM_RESPONSE_PROVIDER_EROADVERTISING,
    label: 'EroAdvertising',
  },
];

export const PAYOUT_TYPE_PUBLISHER_RTB = 1;

export const PAYOUT_TYPE_PUBLISHER_DIRECT = 2;

export const PAYOUT_TYPE_REFERRER = 3;

export const PAYOUT_TYPES: Option[] = [
  {
    value: PAYOUT_TYPE_PUBLISHER_RTB,
    translate_key: 'rtb_publisher',
  },
  {
    value: PAYOUT_TYPE_PUBLISHER_DIRECT,
    translate_key: 'direct_publisher',
  },
  {
    value: PAYOUT_TYPE_REFERRER,
    translate_key: 'referrer',
  },
];

export const PAYOUT_TYPES_MAP = _keyBy(PAYOUT_TYPES, 'value') as OptionsMap;

export const PAYOUT_METHOD_CRYPTO_BITCON = 1;

export const PAYOUT_METHOD_CRYPTO_USDT_ERC20 = 2;

export const PAYOUT_METHOD_CRYPTO_USDT_TRC20 = 3;

export const PAYOUT_METHOD_CRYPTO_USDC = 4;

export const PAYOUT_METHOD_PAYONEER = 5;

export const PAYOUT_METHOD_PAYPAL = 6;

export const PAYOUT_METHOD_WIRE = 7;

export const PAYOUT_METHOD_WEBMONEY = 8;

export const PAYOUT_METHODS: Option[] = [
  {
    value: PAYOUT_METHOD_CRYPTO_BITCON,
    label: 'Bitcoin',
  },
  {
    value: PAYOUT_METHOD_CRYPTO_USDT_ERC20,
    label: 'USDT ERC-20',
  },
  {
    value: PAYOUT_METHOD_CRYPTO_USDT_TRC20,
    label: 'USDT TRC-20',
  },
  {
    value: PAYOUT_METHOD_CRYPTO_USDC,
    label: 'USDC',
  },
  {
    value: PAYOUT_METHOD_PAYONEER,
    label: 'Payoneer',
  },
  {
    value: PAYOUT_METHOD_PAYPAL,
    label: 'PayPal',
  },
  {
    value: PAYOUT_METHOD_WIRE,
    label: 'Wire',
  },
  {
    value: PAYOUT_METHOD_WEBMONEY,
    label: 'WebMoney',
  },
];

export const PAYOUT_METHODS_MAP = _keyBy(PAYOUT_METHODS, 'value') as OptionsMap;

export const POSTBACK_STATUS_ERROR_INVALID_REQUEST = 1;

export const POSTBACK_STATUS_ERROR_CLICK_NOT_FOUND = 2;

export const POSTBACK_STATUS_ERROR_DUPLICATE = 3;

export const POSTBACK_STATUS_ERROR_INVALID_PAYOUT = 4;

export const POSTBACK_STATUS_QUEUED = 5;

export const POSTBACK_STATUS_PROCESSED = 6;

export const POSTBACK_STATUSES: Option[] = [
  {
    value: POSTBACK_STATUS_ERROR_INVALID_REQUEST,
    translate_key: 'invalid_request',
    severity: 'danger',
  },
  {
    value: POSTBACK_STATUS_ERROR_CLICK_NOT_FOUND,
    translate_key: 'click_not_found',
    severity: 'danger',
  },
  {
    value: POSTBACK_STATUS_ERROR_DUPLICATE,
    translate_key: 'duplicate',
    severity: 'warning',
  },
  {
    value: POSTBACK_STATUS_ERROR_INVALID_PAYOUT,
    translate_key: 'invalid_payout',
    severity: 'danger',
  },
  {
    value: POSTBACK_STATUS_QUEUED,
    translate_key: 'queued',
    severity: 'info',
  },
  {
    value: POSTBACK_STATUS_PROCESSED,
    translate_key: 'processed',
    severity: 'success',
  },
];

export const POSTBACK_STATUSES_MAP = _keyBy(POSTBACK_STATUSES, 'value') as OptionsMap;

// Константы для платежей

export const PAYMENT_METHOD_MANUALLY = 1;

export const PAYMENT_METHOD_COINBASE = 2; // Deprecated

export const PAYMENT_METHOD_STRIPE = 3;

export const PAYMENT_METHOD_CAPITALIST = 4;

export const PAYMENT_METHODS: Option[] = [
  {
    value: PAYMENT_METHOD_MANUALLY,
    translate_key: 'manually',
  },
  {
    value: PAYMENT_METHOD_COINBASE,
    translate_key: 'coinbase',
  },
  {
    value: PAYMENT_METHOD_STRIPE,
    translate_key: 'stripe',
  },
  {
    value: PAYMENT_METHOD_CAPITALIST,
    translate_key: 'capitalist',
  },
];

export const PAYMENT_METHODS_MAP = _keyBy(PAYMENT_METHODS, 'value') as OptionsMap;

export const PAYMENT_STATUS_NEW = 1;

export const PAYMENT_STATUS_PENDING = 2;

export const PAYMENT_STATUS_COMPLETED = 3;

export const PAYMENT_STATUS_EXPIRED = 4;

export const PAYMENT_STATUS_CANCELED = 5;

export const PAYMENT_STATUSES: Option[] = [
  {
    value: PAYMENT_STATUS_NEW,
    translate_key: 'new_male',
    severity: 'info',
  },
  {
    value: PAYMENT_STATUS_PENDING,
    translate_key: 'pending_male',
    severity: 'info',
  },
  {
    value: PAYMENT_STATUS_COMPLETED,
    translate_key: 'completed_male',
    severity: 'success',
  },
  {
    value: PAYMENT_STATUS_EXPIRED,
    translate_key: 'expired_male',
    severity: 'warning',
  },
  {
    value: PAYMENT_STATUS_CANCELED,
    translate_key: 'canceled_male',
    severity: 'info',
  },
];

export const PAYMENT_STATUSES_MAP = _keyBy(PAYMENT_STATUSES, 'value') as OptionsMap;

export const PAYMENT_AMOUNT_MIN = 50;

export const PAYMENT_AMOUNT_MIN_STAFF = 2;

// AdSecure

export const ADSECURE_CHECK_STATUS_NOT_CHECKED = 1;

export const ADSECURE_CHECK_STATUS_HAS_VIOLATIONS = 2;

export const ADSECURE_CHECK_STATUS_CLEAN = 3;

export const ADSECURE_CHECK_STATUSES = [
  {
    value: ADSECURE_CHECK_STATUS_NOT_CHECKED,
    label: 'Not checked',
    severity: 'info',
  },
  {
    value: ADSECURE_CHECK_STATUS_HAS_VIOLATIONS,
    label: 'Has violations',
    severity: 'warning',
  },
  {
    value: ADSECURE_CHECK_STATUS_CLEAN,
    label: 'Clean',
    severity: 'success',
  },
];

export const ADSECURE_CHECK_STATUSES_MAP = _keyBy(ADSECURE_CHECK_STATUSES, 'value') as OptionsMap;

export const ADSECURE_ANALYSIS_STATUS_INITIAL = 1;

export const ADSECURE_ANALYSIS_STATUS_PENDING = 2;

export const ADSECURE_ANALYSIS_STATUS_DONE = 3;

export const ADSECURE_ANALYSIS_STATUS_ERROR = 4;

export const ADSECURE_ANALYSIS_STATUSES = [
  {
    value: ADSECURE_ANALYSIS_STATUS_INITIAL,
    translate_key: 'queued',
    severity: 'info',
  },
  {
    value: ADSECURE_ANALYSIS_STATUS_PENDING,
    translate_key: 'pending_male',
    severity: 'info',
  },
  {
    value: ADSECURE_ANALYSIS_STATUS_DONE,
    translate_key: 'done',
    severity: 'success',
  },
  {
    value: ADSECURE_ANALYSIS_STATUS_ERROR,
    translate_key: 'error',
    severity: 'danger',
  },
] as Option[];

export const ADSECURE_ANALYSIS_STATUSES_MAP = _keyBy(ADSECURE_ANALYSIS_STATUSES, 'value') as OptionsMap;
